import { useEffect, useState } from "react";
import './App.css';
import {   
  BrowserRouter as Router,
  Route, 
  useHistory, useParams,
 } from "react-router-dom";

import QRN from './QRN';
import { decodedTextSpanIntersectsWith } from "typescript";

function redirectToWebInterface() {
  const url = "https://apps.smurakami.com/stores-qr/";
  window.location.href = url;
}

function App() {
  return (
    <Router>
      <Route path="/:key" exact>
        <URLs></URLs>
      </Route>

      <Route path="/" exact>
        <Top></Top>
      </Route>
    </Router>
  )
}

function Top() {
  function init() {
    // (async () => {
    //   let key = await QRN.shared.add("https://test-url-2-3");
    //   console.log("added:", key);
    //   let url = await QRN.shared.get(key);
    //   console.log("get:", url);
    // })();

    // redirectToWebInterface();

    function deinit() { }
    return deinit;
  }
  useEffect(init);
  return (
    <div>coming soon</div>
  );
}

interface URLsParameters {
  key: string,
}

function URLs() {
  const history = useHistory();
  const params = useParams<URLsParameters>();
  const [notFound, setNotFound] = useState(false);

  function init() {
    (async () => {

      let url = await QRN.shared.get(params.key).catch(e => console.error(e));
      console.log(url);

      if (url) {
        window.location.href = url;
      } else {
        redirectToWebInterface();
        setNotFound(true);
      }

    })();

    function deinit() { }
    return deinit;
  }

  useEffect(init, []);

  if (notFound) {
    return (
      <div>data not found</div>
    );
  }

  return (
    <div></div>
  )
}

export default App;
