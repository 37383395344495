import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import firebase from "firebase";
import "firebase/firestore"

firebase.initializeApp({
  apiKey: "AIzaSyALJyMjN1Bo_bSeDXmNrIcTQaUzByXa3SU",
  authDomain: "qrn-database.firebaseapp.com",
  projectId: "qrn-database",
  storageBucket: "qrn-database.appspot.com",
  messagingSenderId: "335325607599",
  appId: "1:335325607599:web:b51a64359eaefece38c65a",
  measurementId: "G-PCVVW66KL7"
});

declare global {
  interface Window { debug: any; }
}

window.debug = {};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
