import firebase from "firebase";
import base64url from "base64url";
import crypto from "crypto";

export default class QRN {
  constructor() {

  }

  async get(key: string): Promise<string|undefined> {
    const db = firebase.firestore();

    const snapshot = await db.collection("urls").doc(key).get();
    const data = snapshot.data();

    if (data) {
      return data.url;
    } else {
      return undefined;
    }
  }

  async add(url: string) {
    const db = firebase.firestore();
    let key = this.hash(url);

    let snapshot = await db.collection("urls").doc(key).get();
    let data = snapshot.data();

    while (data && data.url !== url) {
      key = this.hash(key);

      snapshot = await db.collection("urls").doc(key).get();
      data = snapshot.data();
    }

    if (data && data.url === url) {
        // do nothing
        return key;
    } 

    const ref = db.collection("urls").doc(key);
    await ref.set({url});

    return key;
  }

  hash(text: string): string {
    let hashLength = 10;

    let buf = crypto.createHash('sha256').update(text, 'utf8').digest();
    let hash = base64url(buf);
    hash = hash.slice(hash.length-hashLength, hash.length);
    
    return hash;
  }

  static shared = new QRN();
}

